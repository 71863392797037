<template>
    <div class="py-12 lg:py-24">
        <div class="container">
            <div class="max-w-[957px] mx-auto">
                <div class="mb-5 text-blacks-100 text-2xl sm:text-35px lg:text-5xl font-medium text-center">
                    <v-content option_key="inspired_help_title" />
                </div>
                <div class="text-blacks-100 text-[18px] !leading-[144.444%] font-medium text-center">
                    <v-content option_key="inspired_help_intro" />
                </div>
            </div>
        </div>
    </div>
</template>