<template>
    <GlobalBannerEffect />
    <section-help />
    <section-slide :data-slide="dataSlide?.banners?.banner_items" />
    <section-style :data-style="styles" />
    <section-idea :data-idea="gallery" />
</template>

<script setup lang="ts">
import SectionHelp from './section-help.vue'
import SectionSlide from './section-slide.vue'
import SectionStyle from './section-style.vue'
import SectionIdea from './section-idea.vue'
const { getBanner } = useBanner()
const { getFeaturedGalleries, getFeaturedStyles } = useInspiration()
const resNews = await Promise.all([
    getBanner({ name: 'Inspired_slide' }),
    getFeaturedStyles({ query: { limit: -1 } }),
    getFeaturedGalleries({ query: { limit: -1 } })
])

const dataSlide = ref(resNews[0])
const styles = ref(resNews[1])
const gallery = ref(resNews[2])
useDirectusCollectionSeo('inspirating', {})
</script>
